import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import ImagenEmpresa from '../components/imageComponents/imageEmpresa'

const EmpresaPage = () => (
  <Layout>
    <SEO title='OR-PRINTER' />
    <Header siteTitle='OR-PRINTER' />

<Container>

        <div className='row '>


        <div className='col-sm-12 col-md-5 '>
            <ImagenEmpresa />
          </div>
          <div className='col-sm-12 col-md-7 col-lg-7 conmargen '>

          <p ><b><span className="oro"> OR-PRINTER </span></b> se funda en Abril de 1985, con el objetivo de fabricar una pequeña máquina manual de termoimpresión para la imprenta. 
          En 1990 se amplía la gama de modelos de maquinas pasando a fabricar modelos mas automatizados con el fin de producir para mas sectores y mas producción. 
          Con un claro espíritu de innovación, no ha parado de evolucionar desde entonces, produciendo en estos momentos 5 tipos de maquinas.
        </p>
        <p  >
        <b><span className="oro"> OR-PRINTER </span></b> cuenta con una gran implantación a nivel nacional y exporta gran parte de su producción 
        al resto de Europa y América, siendo en estos momentos la producción destinada a la exportación un 90% de la fabricación.
        </p>
        <p  >
        <b><span className="oro"> OR-PRINTER </span></b> da soporte a todo tipo de trabajos de impresión, suministrando tanto las máquinas cómo todos los consumibles necesarios. 
        Películas en toda la gama de colores y grabados.
        </p>
        </div></div>

    </Container>


  </Layout>
)

export default EmpresaPage
